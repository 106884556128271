<template>
  <div>
    <!-- pill tabs inside card -->
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-col md="12">
        <!-- <b-tabs pills nav-class="mb-3">
          <b-tab title="Business Overview" active> -->
        <b-row class="match-height">
          <b-col xl="4" md="4" sm="6">
            <b-card no-body>
              <b-card-header b-card-header class="align-items-baseline">
                <div><b-card-title class="mb-25"> Profit and Loss </b-card-title></div>
                <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                  <feather-icon id="profit-settings-icon" size="16" icon="SettingsIcon" class="cursor-pointer" />

                  <!-- Setting Item Form -->
                  <b-popover triggers="click" target="profit-settings-icon" placement="righttop">
                    <b-form @submit.prevent>
                      <b-row>
                        <!-- Field: Discount -->
                        <b-col cols="12">
                          <b-form-group label="Date">
                            <flat-pickr placeholder="Date Range" v-model="rangeDateProfit" onClose="testClose" class="form-control" :config="config" />

                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-popover>
                </div>
              </b-card-header>

              <b-card-body>
                <h3 class="mt-1">$ {{ formatPrice(netEarnings, 2) }}</h3>
                <h6 class="mb-2">Net Income</h6>
                <b-card-text class="font-weight-bolder  mb-1"> Income: ${{ formatPrice(totalIncome, 2) }} </b-card-text>

                <b-progress class="mb-2" :value="totalIncome" :max="totalIncome + totalCostOfSales" height="20px" variant="success" />
                <b-card-text class="font-weight-bolder mb-1"> Expense: ${{ formatPrice(totalCostOfSales, 2) }} </b-card-text>

                <b-progress class="mb-2" :value="totalCostOfSales" :max="totalIncome + totalCostOfSales" height="20px" variant="warning" />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col>
            <b-card no-body>
              <b-card-header class="align-items-baseline">
                <div>
                  <b-card-title class="mb-25">
                    Sales
                  </b-card-title>
                  <b-card-text class="mb-0"> </b-card-text>
                </div>
                <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                  <feather-icon id="form-item-settings-icon" size="16" icon="SettingsIcon" class="cursor-pointer" />

                  <!-- Setting Item Form -->
                  <b-popover triggers="click" target="form-item-settings-icon" placement="lefttop">
                    <b-form @submit.prevent>
                      <b-row>
                        <!-- Field: Filter Date -->
                        <b-col cols="12">
                          <b-form-group label="Date">
                            <v-select
                              v-model="chartFilter"
                              :options="[
                                { id: 'A', value: 'Daily' },
                                { id: 'B', value: 'Monthly' },
                                { id: 'C', value: 'Yearly' },
                              ]"
                              :reduce="(val) => val.id"
                              label="value"
                              :clearable="false"
                              placeholder="Filter by Date"
                            >
                            </v-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-popover>
                </div>
              </b-card-header>

              <b-card-body class="pb-0">
                <!-- apex chart -->
                <vue-apex-charts v-if="salesLine.series[0].data.length > 0" type="line" height="240" :options="salesLine.chartOptions" :series="salesLine.series" />
                  
                </b-card-body>
            </b-card>
          </b-col>


        </b-row>

        <!-- sale report component -->
          <SaleReport/>

          <!-- inventory report component -->
          <InventoryStockReport/>
        

        <!-- <b-row>
              <b-col>
                <b-card no-body>
                  <b-card-header>
                    <b-card-title>Expense Categories</b-card-title>
                    <b-dropdown variant="transparent" class="chart-dropdown " right no-caret toggle-class="p-0 mb-25" size="sm">
                      <b-dropdown-item v-for="day in chartData.lastDays" :key="day">
                        {{ day }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-card-header>

                  <b-card-body>
                    <vue-apex-charts type="donut" height="300" class="my-1" :options="expenseCategoriesDonut.chartOptions" :series="expenseCategoriesDonut.series" />

                    <div
                      v-for="(data, index) in chartData.chartInfo"
                      :key="data.name"
                      class="d-flex justify-content-between"
                      :class="index === chartData.chartInfo.length - 1 ? 'mb-0' : 'mb-1'"
                    >
                      <div class="series-info d-flex align-items-center">
                        <feather-icon :icon="data.icon" size="16" :class="data.iconColor" />
                        <span class="font-weight-bolder ml-75 mr-25">{{ data.name }}</span
                        ><span>- {{ data.usage }}%</span>
                      </div>
                      <div>
                        <span>{{ data.upDown }}%</span>
                        <feather-icon :icon="data.upDown > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon'" :class="data.upDown > 0 ? 'text-success' : 'text-danger'" class="mb-25 ml-25" />
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row> -->

        <!-- 
          </b-tab>
          <b-tab title="Inventory Sales Report">
            <inventory-report></inventory-report>
          </b-tab>
          <b-tab title="Profit and Loss">
            <profit-loss></profit-loss>
          </b-tab>
          <b-tab title="Journal Report" disabled>
          </b-tab>
          <b-tab title="General Ledger" disabled>
          </b-tab>
          <b-tab title="Statement of Cash Flows" disabled>
          </b-tab>
        </b-tabs> -->
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination, BProgress, BCardBody, BTab, BTabs } from "bootstrap-vue";

import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import accountingStoreModule from "@/views/accounting/accountingStoreModule.js";
import ReportsCard from "@core/components/statistics-cards/ReportsCard.vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component';
import SaleReport from './chart-reports/SaleReport.vue'
import InventoryStockReport from './chart-reports/InventoryStockReport.vue'



export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,
    BProgress,
    VueApexCharts,
    BCardBody,
    BTab,
    BTabs,
    vSelect,
    flatPickr,

    ReportsCard,
    SaleReport,
    InventoryStockReport,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // chartData: {},
      // expenseCategoriesDonut: {
      //   series: [],
      //   chartOptions: {
      //     chart: {
      //       toolbar: {
      //         show: false,
      //       },
      //     },
      //     labels: [],
      //     dataLabels: {
      //       enabled: false,
      //     },
      //     legend: { show: false },
      //     comparedResult: [2, -3, 8],
      //     stroke: { width: 0 },
      //     colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
      //   },
      // },


     


      totalProfit: null,
      totalIncome: null,
      totalIncomeSales: null,
      totalIncomeCategories: null,
      totalPurchase: null,
      totalCogs: null,
      totalOp: null,
      adjustmentUp: null,
      adjustmentDown: null,
      totalCostOfSales: null,
      netEarnings: null,
      grossProfit: null,

      config: {
                    
                    dateFormat: 'd/m/Y',
                    mode: 'range',
                },

      chartFilter: "B",

      rangeDateProfit: null,
      filterDateStartProfit:null,
      filterDateEndProfit:null,
      

      loading: false,
      salesLine: {
        series: [
          {
            name: "Sales",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ["#df87f2"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
            tickAmount: 8,
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
              // formatter(val) {
              //   return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              // },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    };
  },

  watch: {
    chartFilter() {
      this.getChart();
    },

  

    
    rangeDateProfit: {
      handler: function(val, before) {
        if (val.includes('to')) {
          this.filterDateStartProfit = this.rangeDateProfit.split(' to ')[0];
          this.filterDateEndProfit = this.rangeDateProfit.split(' to ')[1];

          this.getProfit();

        }
      },
    },
  },

  methods: {
    getChart() {
      this.salesLine.chartOptions.xaxis.categories = [];
      this.salesLine.series[0].data = [];
      store.dispatch("accounting/businessOverviewSale", { periodChart: this.chartFilter }).then((res) => {
        if (this.chartFilter == "A") {
          var keys = Object.keys(res.data.saleQuoteData);
          keys.forEach((element) => {
            var today = new Date();
            var category = this.months[today.getMonth() - 1] + " " + String(element);
            this.salesLine.chartOptions.xaxis.categories.push(category);
            this.salesLine.series[0].data.push(res.data.saleQuoteData[element].length);
          });
        } else if (this.chartFilter == "B") {
          var keys = Object.keys(res.data.saleQuoteData);
          keys.forEach((element) => {
            this.salesLine.chartOptions.xaxis.categories.push(this.months[element - 1]);
            this.salesLine.series[0].data.push(res.data.saleQuoteData[element].length);
          });
        } else {
          var keys = Object.keys(res.data.saleQuoteData);
          keys.forEach((element) => {
            this.salesLine.chartOptions.xaxis.categories.push(element);
            this.salesLine.series[0].data.push(res.data.saleQuoteData[element].length);
          });
        }
      });
    },
    getProfit() {
      store.dispatch("accounting/businessOverviewInventory", { start: this.filterDateStartProfit, end: this.filterDateEndProfit }).then((res) => {
        this.totalProfit = res.data.totalProfit;
        this.totalIncomeSales = res.data.totalIncomeSales;
        this.totalPurchase = res.data.totalPurchase;
        this.categories = res.data.categories;
        this.adjustmentUp = res.data.adjustmentUp;
        this.adjustmentDown = res.data.adjustmentDown;
        this.totalCogs = res.data.totalCogs;
        this.totalOps = res.data.totalOps;
        this.totalIncomeCategories = res.data.totalIncomeCategories;
        this.categoriesOperational = res.data.categoriesOperational;
        this.categoriesCogs = res.data.categoriesCogs;
        this.categoriesIncome = res.data.categoriesIncome;
        this.totalIncome = res.data.totalIncome;
        this.totalCostOfSales = res.data.totalCostOfSales;
        this.grossProfit = res.data.grossProfit;
        this.netEarnings = res.data.netEarnings;

        // this.categories.forEach((cogs)=>{
        //   if(cogs.cogsAmount){
        //   this.expenseCategoriesDonut.series.push(cogs.cogsAmount)
        //   this.expenseCategoriesDonut.chartOptions.labels.push(cogs.categoryName)
        //   }
        //   else if(cogs.amount){
        //     this.expenseCategoriesDonut.series.push(cogs.amount)
        //     this.expenseCategoriesDonut.chartOptions.labels.push(cogs.categoryName)
        //   }
        // })

        this.loading = false;
      });
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },

  created() {
    this.loading = true;
    this.getChart();
    this.getProfit();
  },

  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = "accounting";

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
